import React, { useEffect } from "react";
import DataTable from "../components/DataTable/DataTable";
import {
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  Select,
  Flex,
} from "@chakra-ui/react";
import usePersonalContext from "../context/PersonalContext";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom/dist";

export default function Personal() {
  const {
    personal,
    loadingPersonal,
    getPersonal,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    filters,
    setFilters,
    errorPersonal,
  } = usePersonalContext();

  const columns = [
    {
      keydata: "emp_code",
      type: "string",
      title: "Código",
      width: "300px",
    },
    { keydata: "first_name", type: "string", title: "Nombre", width: "100px" },
    {
      keydata: "last_name",
      type: "string",
      title: "Apellido",
      width: "100px",
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    getPersonal();
  }, [filters, currentPage, pageSize]);

  errorPersonal === 401 ? navigate("/login") : <Outlet />;

  const handleChangeFilter = (e) => {
    e.preventDefault();
    if (currentPage > 1) setCurrentPage(1);
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Text as="b" fontSize="2xl">
        Personal
      </Text>
      <FormControl w={"200px"} mr={"50px"} mt={5} mb={6}>
        <FormLabel fontSize={ "14px" }>Buscar: </FormLabel>
        <Input
          type="text"
          name={"search_value"}
          onChange={handleChangeFilter}
          fontSize={ "14px" }
        />
      </FormControl>
      {personal ? (
        !loadingPersonal ? (
          personal.data.length === 0 ? (
            "No hay resultados"
          ) : (
            <DataTable
              cols={columns}
              rows={personal}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              loading={loadingPersonal}
            />
          )
        ) : (
          <Spinner />
        )
      ) : (
        <Spinner />
      )}
    </>
  );
}
