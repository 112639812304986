import React from "react";
import { Button, IconButton, Flex } from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";

export default function DataTablePag({
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  pages,
  loading,
}) {
  let pagesArray = [];
  for (let i = 0; i < pages; i++) {
    pagesArray.push(i + 1);
  }

  pagesArray = pagesArray.length === 0 ? [1] : pagesArray;
  return (
    <Flex align={"center"} justify={"space-around"} w={"100%"}>
      <div>
        <IconButton
          color="teal"
          size="xs"
          mr={1}
          icon={<ArrowLeftIcon />}
          disabled={currentPage === 1 || loading}
          onClick={() => {
            setCurrentPage(1);
            setPageSize(pageSize);
          }}
        />
        <IconButton
          color="teal"
          size="sm"
          mr={1}
          icon={<ChevronLeftIcon boxSize={6} />}
          disabled={currentPage === 1 || loading}
          onClick={() => {
            setCurrentPage(currentPage - 1);
            setPageSize(pageSize);
          }}
        />
        {pagesArray.slice(currentPage - 1, currentPage + 2).map((p) => (
          <Button
            w={'20px'}
            key={p}
            mr={3}
            p={4}
            size="xs"
            colorScheme={currentPage === p ? "teal" : "gray"}
            onClick={() => {
              setCurrentPage(p);
              setPageSize(pageSize);
            }}
            disabled={loading}
          >
            {p}
          </Button>
        ))}
        <IconButton
          color="teal"
          size="xs"
          mr={1}
          icon={<ChevronRightIcon boxSize={6} />}
          disabled={currentPage === pagesArray.length || loading}
          onClick={() => {
            setCurrentPage(currentPage + 1);
            setPageSize(pageSize);
          }}
        />
        <IconButton
          color="teal"
          size="xs"
          mr={1}
          icon={<ArrowRightIcon />}
          disabled={currentPage === pagesArray.length || loading}
          onClick={() => {
            setCurrentPage(pagesArray.length);
            setPageSize(pageSize);
          }}
        />
      </div>
    </Flex>
  );
}
