import React, { useState } from "react";
import { Center, Heading } from "@chakra-ui/react";
import { Card, CardHeader, CardBody, Button } from "@chakra-ui/react";
import {
  Input,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { Checkbox } from "@chakra-ui/react";
import useAuthContext from "../context/AuthContext";

export default function Login() {
  const { login, errorData, loadingAuth } = useAuthContext();

  const [userData, setUserData] = useState({
    username: "",
    password: "",
    remember: true,
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleCheckbox = (e) => {
    e.preventDefault();
    setUserData({ ...userData, [e.target.name]: e.target.checked });
  };

  const submit = (e) => {
    e.preventDefault();
    login(userData);
  };

  const [show, setShow] = useState(false);
  const showPswd = () => setShow(!show);

  return (
      <Center h="100vh">
        <Card w={"500px"} mr={3} ml={3} p={2} border={"1px solid #b8b6b6"}>
          <CardHeader align="center">
            <Heading size="md">Sistema de asistencias</Heading>
          </CardHeader>
          <CardBody>
            <form onSubmit={submit}>
              <FormControl isInvalid={errorData.username}>
                <FormLabel>Usuario</FormLabel>
                <Input
                  type="text"
                  name="username"
                  onChange={handleChange}
                  disabled={loadingAuth}
                />
                {!errorData.username ? (
                  <FormHelperText>Ingresa tu usuario</FormHelperText>
                ) : (
                  <FormErrorMessage>{errorData.username[0]}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl mt={5} isInvalid={errorData.password}>
                <FormLabel>Contraseña</FormLabel>
                <InputGroup>
                  <Input
                    type={show ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    disabled={loadingAuth}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={showPswd}
                      disabled={loadingAuth}
                    >
                      {show ? "Ocultar" : "Mostrar"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {!errorData.password ? (
                  <FormHelperText>Ingresa tu contraseña</FormHelperText>
                ) : (
                  <FormErrorMessage>{errorData.password[0]}</FormErrorMessage>
                )}
              </FormControl>
              <Checkbox
                mt={5}
                name="remember"
                defaultChecked
                onChange={handleCheckbox}
                disabled={loadingAuth}
              >
                Recuérdame
              </Checkbox>
              <Button
                w={"100%"}
                mt={6}
                align="center"
                colorScheme="blue"
                type="submit"
                isLoading={loadingAuth}
              >
                Ingresar
              </Button>
            </form>
          </CardBody>
        </Card>
      </Center>
  );
}
