import { createBrowserRouter } from "react-router-dom";
import { AuthProvider } from "../context/AuthContext";
import Layout from "../layout/Layout";
import Asistencias from "../pages/Asistencias";
import Login from "../pages/Login";
import Personal from "../pages/Personal";
import NotFound from "../pages/NotFound";
import { ProtectedRoute } from "../components/ProtectedRoute";
import { ProtectedRouteLogin } from "../components/ProtectedRouteLogin";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      </AuthProvider>
    ),
    errorElement: <NotFound/> ,
    children: [
      {
        index: true,
        element: <Asistencias />,
      },
      {
        path: "/personal",
        element: <Personal />,
      },
    ],
  },
  {
    path: "/login",
    element: (
      <AuthProvider>
        <ProtectedRouteLogin>
          <Login />
        </ProtectedRouteLogin>
      </AuthProvider>
    ),
    errorElement: <NotFound/> 
  },
]);
