import React from 'react'
import { Navigate } from 'react-router'

export const ProtectedRouteLogin = ({children}) => {
    if(localStorage.getItem('token' )) {
        return <Navigate to={'/'} />
    } else if(sessionStorage.getItem('token' )) {
        return <Navigate to={'/'} />
    }
    return children
}
