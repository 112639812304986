import * as React from "react";
import { Text, Grid, GridItem } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Icon, IconButton } from "@chakra-ui/react";
import { SlLogout } from "react-icons/sl";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom/dist";
import { AsistenciaProvider } from "../context/AsistenciasContext";
import { PersonalProvider } from "../context/PersonalContext";
import useAuthContext from "../context/AuthContext";

export default function Layout() {
  const { logout, loadingAuth } = useAuthContext();
  return (
    <PersonalProvider>
      <AsistenciaProvider>
        {loadingAuth ? (
          "Cerrando sesión..."
        ) : (
          <Grid
            templateAreas={`"header"
              "main"
              "footer"`}
            gridTemplateRows={"50px 1fr"}
            gridTemplateColumns={"100% 1fr"}
            h="100vh"
            gap="0"
          >
            <GridItem
              pl={3}
              bg="teal"
              area={"header"}
              display={"flex"}
              alignItems={"center"}
            >
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<HamburgerIcon />}
                  variant="outline"
                  color={"white"}
                  _hover={{ bg: "white", color: "teal" }}
                  _active={{ bg: "white", color: "teal" }}
                  _focus={{ bg: "white", color: "teal" }}
                />
                <MenuList colorScheme="teal">
                  <Link to={"/"}>
                    <MenuItem>Asistencias</MenuItem>
                  </Link>
                  <Link to={"/personal"}>
                    <MenuItem>Personal</MenuItem>
                  </Link>
                  <MenuDivider />
                  <MenuItem icon={<Icon as={SlLogout} />} onClick={logout}>
                    Cerrar sesión
                  </MenuItem>
                </MenuList>
              </Menu>
              <Text ml={6} color={"white"} fontSize="xl">
                Sistema de asistencias
              </Text>
            </GridItem>
            <GridItem area={"main"} p={"25px"}>
              <Outlet />
            </GridItem>
          </Grid>
        )}
      </AsistenciaProvider>
    </PersonalProvider>
  );
}
