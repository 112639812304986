import { Center, Heading } from "@chakra-ui/react";
import React from "react";

export default function NotFound() {
  return (
    <Center h={"100vh"} flexDirection={"column"}>
      <Heading as="h1" size="4xl" noOfLines={1} mb={3}>
        404
      </Heading>
      <div>Página no encontrada 😞</div>
    </Center>
  );
}
