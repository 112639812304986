import React, { useEffect } from "react";
import DataTable from "../components/DataTable/DataTable";
import useAsistenciasContext from "../context/AsistenciasContext";
import {
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom/dist";

export default function Asistencias() {
  const {
    asistencias,
    loadingAsistencias,
    getAsistencias,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    filters,
    setFilters,
    fechaActual,
    errorAsistencias,
  } = useAsistenciasContext();

  const navigate = useNavigate();
  errorAsistencias === 401 ? navigate("/login") : <Outlet />;

  useEffect(() => {
    getAsistencias();
  }, [filters]);

  const columns = [
    {
      keydata: "punch_time",
      type: "time",
      title: "Hora",
    },
    {
      keydata: "emp",
      keyvalue: "first_name",
      type: "object",
      title: "Nombre",
    },
    {
      keydata: "emp",
      keyvalue: "last_name",
      type: "object",
      title: "Apellido",
    },
    {
      keydata: "emp",
      keyvalue: "emp_code",
      type: "object",
      title: "Código Emp.",
    },
    { keydata: "area_alias", type: "string", title: "Área", width: "100px" },
    {
      keydata: "terminal_alias",
      type: "string",
      title: "Terminal",
    },
  ];

  const handleChangeFilter = (e) => {
    e.preventDefault();
    if (currentPage > 1) setCurrentPage(1);
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Text as="b" fontSize="2xl">
        Asistencias
      </Text>
      <Wrap mt={5} mb={6}>
        <WrapItem>
          <FormControl w={"200px"}>
            <FormLabel fontSize={ "14px" }>Fecha Inicio: </FormLabel>
            <Input
              type="date"
              max={fechaActual}
              name={"first_date"}
              defaultValue={fechaActual}
              onChange={handleChangeFilter}
              disabled={loadingAsistencias}
              fontSize={ "14px" }
            />
          </FormControl>
        </WrapItem>
        <WrapItem>
          <FormControl w={"200px"}>
            <FormLabel fontSize={ "14px" }>Fecha Final: </FormLabel>
            <Input
              type="date"
              max={fechaActual}
              name={"last_date"}
              defaultValue={fechaActual}
              onChange={handleChangeFilter}
              disabled={loadingAsistencias}
              fontSize={ "14px" }
            />
          </FormControl>
        </WrapItem>
        <WrapItem>
          <FormControl w={"200px"} >
            <FormLabel fontSize={ "14px" }>Buscar: </FormLabel>
            <Input
              type="text"
              name={"search_value"}
              onChange={handleChangeFilter}
              fontSize={ "14px" }
            />
          </FormControl>
        </WrapItem>
      </Wrap>

      {asistencias ? (
        !loadingAsistencias ? (
          asistencias.data.length === 0 ? (
            "No hay resultados"
          ) : (
            <DataTable
              cols={columns}
              rows={asistencias}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              loading={loadingAsistencias}
              disablePagination
            />
          )
        ) : (
          <Spinner />
        )
      ) : (
        <Spinner />
      )}
    </>
  );
}
