import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Spinner, Select } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";
import DataTablePag from "./DataTablePag";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

export default function DataTable({
  cols,
  rows,
  currentPage,
  setCurrentPage,
  pageSize,
  setPageSize,
  loading,
  disablePagination,
}) {
  const handlePageSize = (e) => {
    setPageSize(e.target.value);
  };
  console.log(rows);
  return (
    <>
      <Flex align={"center"}>
        {!disablePagination && (
          <>
            <span>Ver: </span>
            <Select
              defaultValue={pageSize}
              ml={1}
              width={"80px"}
              onChange={handlePageSize}
              disabled={loading}
              fontSize={"14px"}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="40">40</option>
            </Select>
          </>
        )}

        <Text fontSize="14px" ml={4}>
          {"Mostrando " +
            rows.meta.from +
            "-" +
            rows.meta.to +
            " de " +
            rows.meta.total}
        </Text>
      </Flex>
      {loading ? (
        <Spinner mt={4} />
      ) : (
        <TableContainer mt={4} mb={5}>
          <Table variant="simple">
            <Thead>
              <Tr>
                {cols.map((c, i) => (
                  <Th key={i}>{c.title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {rows.data.map((r, i) => (
                <>
                  <Tr key={i}>
                    {cols.map((c, i) => (
                      <>
                        <Td
                          key={i}
                          maxW={c.width}
                          overflow={"hidden"}
                          fontSize={"14px"}
                        >
                          {c.type === "boolean" ? (
                            r[c.keydata] ? (
                              <CheckIcon />
                            ) : (
                              <CloseIcon boxSize={3} />
                            )
                          ) : c.type === "object" ? (
                            r[c.keydata][c.keyvalue]
                          ) : c.type === "time" ? (
                            new Date(r[c.keydata].replace(/\s/, "T"))
                              .getDate()
                              .toString()
                              .padStart(2, 0) +
                            "/" +
                            (
                              new Date(
                                r[c.keydata].replace(/\s/, "T")
                              ).getMonth() + 1
                            )
                              .toString()
                              .padStart(2, 0) +
                            "/" +
                            new Date(r[c.keydata].replace(/\s/, "T"))
                              .getFullYear()
                              .toString()
                              .padStart(2, 0) +
                            " - " +
                            new Date(r[c.keydata].replace(/\s/, "T"))
                              .getHours()
                              .toString()
                              .padStart(2, 0) +
                            ":" +
                            new Date(r[c.keydata].replace(/\s/, "T"))
                              .getMinutes()
                              .toString()
                              .padStart(2, 0) +
                            ":" +
                            new Date(r[c.keydata].replace(/\s/, "T"))
                              .getSeconds()
                              .toString()
                              .padStart(2, 0)
                          ) : (
                            r[c.keydata]
                          )}
                        </Td>
                      </>
                    ))}
                  </Tr>
                </>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {!disablePagination && (
        <Stack
          direction={["column", "row"]}
          spacing="24px"
          justifyContent={"center"}
        >
          <DataTablePag
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pages={rows.meta.last_page}
            loading={loading}
          />
        </Stack>
      )}
    </>
  );
}
