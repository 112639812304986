import React from 'react'
import { Navigate } from 'react-router'

export const ProtectedRoute = ({children}) => {
    if(localStorage.getItem('token' )) {
        return children
    } else if(sessionStorage.getItem('token' )) {
        return children
    }
    return <Navigate to={'/login'} />
}
