import { createContext, useContext, useState } from "react";
import axios from "axios";

const PersonalContext = createContext({});

export const PersonalProvider = ({ children }) => {
  const [personal, setPersonal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [errorPersonal, setErrorPersonal] = useState([]);
  const [loadingPersonal, setLoadingPersonal] = useState(false);

  const [filters, setFilters] = useState({});

  const http = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      "X-Request-With": "XMLHttpRequest",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
    },
    withCredentials: true,
  });

  const getPersonal = async () => {
    setLoadingPersonal(true);
    setErrorPersonal([]);
    await http
      .post(`/personal?page=${currentPage}&page_size=${pageSize}`, filters)
      .then((response) => {
        setPersonal(response.data);
        setLoadingPersonal(false);
      })
      .catch((error) => {
        setErrorPersonal(error.response.status);
      });
    setLoadingPersonal(false);
  };

  return (
    <PersonalContext.Provider
      value={{
        personal,
        errorPersonal,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        getPersonal,
        loadingPersonal,
        filters,
        setFilters,
      }}
    >
      {children}
    </PersonalContext.Provider>
  );
};

export default function usePersonalContext() {
  return useContext(PersonalContext);
}
