import { createContext, useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [errorData, setErrorData] = useState([]);
  const [loadingAuth, setIsLoadingAuth] = useState(false);
  const navigate = useNavigate();

  const http = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      "X-Request-With": "XMLHttpRequest",
      Authorization:
        `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
    },
    withCredentials: true,
  });

  const login = async ({ ...data }) => {
    setIsLoadingAuth(true);
    http
      .post("/login", data)
      .then((response) => {
        setErrorData([]);
        data.remember
          ? localStorage.setItem("token", response.data.access_token)
          : sessionStorage.setItem("token", response.data.access_token);
        setIsLoadingAuth(false);
        navigate("/");
      })
      .catch((error) => {
        console.log(error)
        setErrorData(error.response.data.errors);
        setIsLoadingAuth(false);
      });
  };

  const logout = async () => {
    setIsLoadingAuth(true);
    http.post("/logout").then(() => {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      navigate("/login");
      setIsLoadingAuth(false);
    });
  };

  return (
    <AuthContext.Provider value={{ errorData, login, logout, loadingAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuthContext() {
  return useContext(AuthContext);
}
