import { createContext, useContext, useState } from "react";
import axios from "axios";

const AsistenciasContext = createContext({});

export const AsistenciaProvider = ({ children }) => {
  const [asistencias, setAsistencias] = useState(false);
  const [errorAsistencias, setErrorAsistencias] = useState([]);
  const [loadingAsistencias, setLoadingAsistencias] = useState(false);

  const format = (fec) => {
    if (fec instanceof Date) {
      const year = fec.getFullYear();
      const month = fec.getMonth() + 1;
      const day = fec.getDate();
      return (
        year +
        "-" +
        (month > 9 ? "" : "0") +
        month +
        "-" +
        (day > 9 ? "" : "0") +
        day
      );
    } else {
      return "No es una fecha";
    }
  };
  const fechaActual = format(new Date(Date.now()));

  const [filters, setFilters] = useState({
    first_date: fechaActual,
    last_date: fechaActual
  });

  const http = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      "X-Request-With": "XMLHttpRequest",
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        `Bearer ${localStorage.getItem('token') || sessionStorage.getItem('token')}`,
    },
    withCredentials: true,
  });

  const getAsistencias = async () => {
    setLoadingAsistencias(true);
    setErrorAsistencias([]);
    await http
      .post(`/asistencias`, filters)
      .then((response) => {
        console.log(response)
        setAsistencias(response.data)
        setLoadingAsistencias(false);
      })
      .catch((error) => {
        console.log(error)
        setErrorAsistencias(error.response.status);
      });
    setLoadingAsistencias(false);
  };

  return (
    <AsistenciasContext.Provider
      value={{
        asistencias,
        errorAsistencias,
        getAsistencias,
        loadingAsistencias,
        filters,
        setFilters,
        fechaActual,
      }}
    >
      {children}
    </AsistenciasContext.Provider>
  );
};

export default function useAsistenciasContext() {
  return useContext(AsistenciasContext);
}
